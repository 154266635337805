export const normalizeCountryName = (country: string) => {
  const countryMap: any = {
    '419': 'Latin America',
    'AE': 'United Arab Emirates',
    'AT': 'Austria',
    'AU': 'Australia',
    'AZ': 'Azerbaijan',
    'AR': 'Argentina',
    'BE': 'Belgium',
    'BR': 'Brazil',
    'CA': 'Canada',
    'CH': 'Switzerland',
    'CL': 'Chile',
    'CO': 'Colombia',
    'DK': 'Denmark',
    'EC': 'Ecuador',
    'FI': 'Finland',
    'FR': 'France',
    'DE': 'Germany',
    'HN': 'Honduras',
    'IN': 'India',
    'IT': 'Italy',
    'JO': 'Jordan',
    'JP': 'Japan',
    'MX': 'Mexico',
    'NL': 'Netherlands',
    'NO': 'Norway',
    'PE': 'Peru',
    'PL': 'Poland',
    'PT': 'Portugal',
    'PY': 'Paraguay',
    'RU': 'Russia',
    'ES': 'Spain',
    'EN': 'English',
    'SE': 'Sweden',
    'TR': 'Turkey',
    'UK': 'United Kingdom',
    'US': 'United States',
    'UY': 'Uruguay',
    'VE': 'Venezuela',
    'ZA': 'South Africa',
    'Buenos Aires': 'ar',
    'Buenos Aires Province': 'ar',
    'Córdoba Province': 'ar',
    'Chaco Province': 'ar',
    'San Luis Province': 'ar',
    'Capital Department': 'ar',
    'argentina': 'ar',
    'County Louth': 'Ireland',
    'state of são paulo': 'br',
    'france': 'fr',
    'Brazil': 'br',
    'brasil': 'br',
    'Turkey': 'tr',
    'Türkiye': 'Turkey',
    'Kumkısık': 'Turkey',
    'Democratic Republic of the Congo': 'DR Congo',
    'GA': 'United States',
    'BC': 'Canada',
    'NSW': 'Australia',
    'NC': 'United States',
    'MO': 'Macau',
    'Yemen': 'Yemen',
    'yemen': 'Yemen',
    'null': 'Unknown',
    'germany': 'de',
    'spain': 'es',
    'españa': 'es',
    'avenida esperança': 'br',
    'são paulo': 'br',
    'state of pernambuco': 'br',
    'state of santa catarina': 'br',
    'albania': 'al',
    'Ireland': 'ie',
    'peru': 'pe',
    'state of bahia': 'br',
    'ceará': 'br',
    'bahia': 'br',
    'buenos aires': 'ar',
    'state of rio de janeiro': 'br',
    'state of rio grande do sul': 'br',
    'buenos aires province': 'ar',
    'caldas': 'co',
    'capital department': 'ar',
    'angola': 'ao',
    'uganda': 'ug',
    'democratic republic of the congo': 'cd',
    'morocco': 'mo',
    'ma': 'mo'
  };

  return countryMap[country] || country;
};

// utils/countries.js

export const countryNameMap = {
  ar: [
    'argentina',
    'san luis province',
    'córdoba province',
    'chaco province',
    'capital department',
    'buenos aires',
    'buenos aires province',
  ],
  es: ['spain', 'eses', 'españa', 'españa', 'es', 'es'],
  fr: ['france', 'francefrance', 'fr'],
  mx: ['mexico', 'mexico', 'ver.'],
  de: ['germany', 'germanygermany', 'de'],
  us: ['united states', 'usa'],
  tr: [
    'turkey',
    'türkiye',
    'turkiye',
    'kumkısık',
    'tr',
    'türkiye',
    'tr',
    'kumkısık',
  ],
  gb: ['united kingdom', 'uk', 'united kingdom'],
  it: ['italy', 'itit'],
  nl: ['netherlands', 'nlnl'],
  nz: ['new zealand', 'nznz'],
  ca: ['canada', 'caca'],
  fi: ['finland', 'fifi'],
  pl: ['poland', 'plpl'],
  pt: ['portugal', 'portugal'],
  br: ['brazil', 'brbr', 'brazil', 'br'],
  au: ['australia', 'auau'],
  se: ['sweden', 'sese'],
  cl: ['chile', 'clcl'],
  ve: ['venezuela', 'veve'],
  be: ['belgium', 'bebe'],
  ae: ['united arab emirates', 'uae', 'aeae'],
  uy: ['uruguay', 'uyuy'],
  dk: ['denmark', 'dkdk'],
  ru: ['russia', 'russia', 'ruru'],
  no: ['norway', 'nono'],
  jp: ['japan', 'japan', 'jpjp'],
  ie: ['ireland', 'ieie'],
  py: ['paraguay', 'pypy'],
  ch: ['switzerland', 'chch'],
  in: ['india', 'inin'],
  ph: ['philippines', 'phph'],
  cn: ['china', 'china', 'ncnc'],
  jo: ['jordan', 'jojo'],
  cd: ['democratic republic of the congo', 'congo', 'democratic republic of the congo'],
  hn: ['honduras', 'hnhn'],
  za: ['south africa', 'zaza'],
  pe: ['peru', 'pepe'],
  az: ['azerbaijan', 'azaz'],
  bc: ['british columbia', 'bcbc'],
  ga: ['gabon', 'gaga'],
  drc: ['dr congo'],
  mo: ['morocco', 'momo'],
  ec: ['ecuador', 'ecec'],
  co: ['colombia', 'coco', 'caldas province'],
  ye: ['yemen', 'yemen'],
  unknown: ['unknown'],
  bb: ['barbados'],
  en: ['english', 'english', 'en'],
  // Add more mappings as needed based on your data
};

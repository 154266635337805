import React, { useMemo } from "react";
import TableContainer from '../../components/Common/TableContainer';
import { formatDate } from '../../utils/dates';
import {
  SelectColumnFilter,
  SelectColumnPositiveFilter,
  SelectCurrentDay,
  filterForExactMatch,
  filterForPositiveNumber,
  filterForExisItem,
  filterForCurrentDay
} from '../../components/Common/filters';
import useTableData from '../../hooks/useTableData';
import { getUsers } from "../../store/actions";
import {
  ColoredCell,
  CellSingleLine,
  CellThin
} from '../../components/Common/Cells';
import { useNavigate } from 'react-router-dom';
import { langFlags } from '../../common/langFlags';
import { allFlags } from '../../common/allFlags';
import { getCountryCode } from '../../common/countryNames';
import { getTotalSpent } from '../../utils/money';

const Users = () => {
  const {
    data,
    totalItems,
    loading,
    filters,
    pageSize,
    pageIndex,
    totalPages,
    sortBy,
    setFilters,
    setPageSize,
    setPageIndex,
    setSortBy,
    refreshData,
  } = useTableData(getUsers, { dataKey: 'users' });

  const columns = useMemo(
    () => [
      {
        Header: 'Email',
        accessor: 'email',
        Cell: ({ value }) => <CellThin value={value} />
      },
      {
        Header: 'Name',
        accessor: 'name',
        Cell: ({ value }) => <CellThin value={value} />
      },
      {
        Header: 'Gender',
        accessor: 'gender',
        Filter: SelectColumnFilter,
        filter: filterForExactMatch,
        Cell: ({ value }) => (
          <ColoredCell
            value={value === 'male' ? 'M' : value === 'female' ? 'F' : 'X'}
            color={value === 'male' ? 'blue' : value === 'female' ? 'pink' : '#d3dcff'}
            textColor={value === 'male' ? 'white' : value === 'female' ? 'black' : 'black'}
          />
        )
      },
      {
        Header: 'Lang',
        accessor: 'language',
        Filter: SelectColumnFilter,
        filter: filterForExactMatch,
        Cell: ({ value }) => {
          return (
            <img
              src={langFlags[value]}
              alt={value}
              style={{
                width: '20px',
                height: '20px'
              }} />
          )
        }
      },
      {
        Header: 'CT',
        accessor: 'country',
        Filter: SelectColumnFilter,
        filter: filterForExactMatch,
        Cell: ({ value }) => {
          const flagCode = getCountryCode(value);
          if (!flagCode) return null;

          const theFlag = allFlags[flagCode];
          const theName = value;  // Use original value for the name

          return (
            <img
              src={theFlag}
              alt={theName}
              title={theName}
              style={{
                width: '20px',
                height: '20px'
              }} />
          );
        }
      },
      {
        Header: 'Reality',
        accessor: 'currentReality',
        Filter: SelectColumnPositiveFilter,
        filter: filterForExisItem,
        Cell: ({ value }) => (
          <ColoredCell
            value={!!value ? 'Yes' : 'No'}
            color={!!value ? 'green' : 'blanchedalmond'}
            textColor={!!value ? 'white' : ''}
          />
        )
      },
      {
        Header: 'D.',
        accessor: 'currentRealityDay',
        Cell: ({ value }) => value || '',
        Filter: () => null
      },
      {
        Header: 'R. Upd. At',
        accessor: 'updatedAt',
        Cell: ({ value }) => value ? <CellSingleLine value={formatDate(value)} /> : '',
        Filter: () => null
      },
      {
        Header: 'Demo',
        accessor: 'freeDemoCompleted',
        Filter: SelectColumnPositiveFilter,
        filter: filterForExisItem,
        Cell: ({ value }) => (
          <ColoredCell
            showFalse={false}
            value={!!value ? '✅' : 'No'}
            style={{ fontSize: '16px' }}
          />
        )
      },
      {
        Header: 'Ads',
        accessor: 'adsConsented',
        Filter: SelectColumnPositiveFilter,
        filter: filterForExisItem,
        Cell: ({ value }) => (
          <ColoredCell
            showFalse={false}
            value={!!value ? '✅' : 'No'}
            style={{ fontSize: '16px' }}
          />
        )
      },
      {
        Header: 'Ads 👁️',
        accessor: 'totalAdsSeen',
        Filter: SelectColumnPositiveFilter,
        filter: filterForPositiveNumber,
        Cell: ({ value }) => value || '',
        Filter: () => null
      },
      {
        Header: 'Mrkt 📧',
        accessor: 'emailMarketing',
        Filter: SelectColumnPositiveFilter,
        filter: filterForExisItem,
        Cell: ({ value }) => (
          <ColoredCell
            showFalse={false}
            value={!!value ? '✅' : 'No'}
            style={{ fontSize: '16px' }}
          />
        )
      },
      {
        Header: 'Ref.',
        accessor: 'referral.referredUsersTotal',
        Filter: SelectColumnPositiveFilter,
        filter: filterForPositiveNumber,
        Cell: ({ value }) => value || '',
        Filter: () => null
      },
      {
        Header: 'Bought',
        accessor: data => {
          const totalRealitiesAcquired = data.shop.totalRealitiesAcquired;
          const itemsAcquired = data.shop.activity?.itemsAcquired || [];
          const totalSpent = getTotalSpent(itemsAcquired);
          return { totalRealitiesAcquired, totalSpent, itemCount: itemsAcquired.length };
        },
        id: 'shop.totalRealitiesAcquired',
        Filter: SelectColumnPositiveFilter,
        filter: (rows, id, filterValue) => {
          const filterYes = filterValue === 'Yes';
          return rows.filter(row => {
            const cellValue = row.values[id];
            return filterYes ? !!cellValue.totalRealitiesAcquired : true;
          });
        },
        Cell: ({ value }) => {
          const { totalRealitiesAcquired, totalSpent, itemCount } = value;
          return (
            <ColoredCell
              showFalse={false}
              value={totalRealitiesAcquired ? `${totalSpent}` : 'No'}
              color={totalRealitiesAcquired ? 'green' : 'blanchedalmond'}
              textColor={totalRealitiesAcquired ? 'white' : ''}
            >
              {totalRealitiesAcquired && itemCount > 1 && (
                <div className="circle">
                  <span className="circleText">{itemCount}</span>
                </div>
              )}
            </ColoredCell>
          );
        }
      },
      {
        Header: 'OS',
        accessor: 'device',
        Filter: SelectColumnFilter,
        filter: filterForExactMatch,
        filterOptions: ['android', 'ios'],
        Cell: ({ value }) => (
          value === 'android' ? (
            <i className="mdi mdi-android" style={{ fontSize: '19px', color: 'green' }}></i>
          ) : value === 'ios' ? (
            <i className="fas fa-apple-alt" style={{ fontSize: '15px', color: 'black' }}></i>
          ) : null
        )
      },
      {
        Header: 'Created At',
        accessor: 'createdAt',
        Cell: ({ value }) => <CellSingleLine value={formatDate(value)} />,
        Filter: SelectCurrentDay,
        filter: filterForCurrentDay
      }
    ],
    []
  );

  const navigate = useNavigate();

  return (
    <div className="page-content">
      <TableContainer
        className="custom-header-css"
        columns={columns}
        data={data}
        refreshData={refreshData}
        isLoading={loading}
        isGlobalFilter={true}
        isAddOptions={false}
        pageIndex={pageIndex}
        pageSize={pageSize}
        totalItems={totalItems}
        totalPages={totalPages}
        setFilters={setFilters}
        setPageSize={setPageSize}
        setPageIndex={setPageIndex}
        setSortBy={setSortBy}
        sortBy={sortBy}
        filters={filters}
        onRowClick={(userId) => {
          console.log(userId);
          window.open(`/user/${userId}`, '_blank');
        }}
      />
    </div>
  );
};

export default Users;

import { db } from '../firebase';
import { collection, getDocs, query, where } from 'firebase/firestore';

/**
 * Función para extraer solo los números de una cadena.
 * @param {string} str - La cadena de entrada.
 * @returns {string} - La cadena resultante solo con números.
 */
const extractNumbers = (str) => str.replace(/\D/g, '');

export const fetchEmailsFromFirestore = async (criteria) => {
  const usersRef = collection(db, 'users');
  let filters = [];

  // Aplicar filtros según los criterios proporcionados
  if (criteria.purchasers) {
    filters.push(where('shop.totalRealitiesAcquired', '>', 0));
  }
  if (criteria.marketingAccepted) {
    filters.push(where('emailMarketing', '==', true));
  }
  if (criteria.nonPurchasers) {
    filters.push(where('shop.totalRealitiesAcquired', '==', 0));
  }
  if (criteria.realityCreated) {
    filters.push(where('shop.totalRealitiesCreated', '>', 0));
  }
  if (criteria.nonRealityCreated) {
    filters.push(where('shop.totalRealitiesCreated', '==', 0));
  }

  const selectedLanguages = Object.keys(criteria.languages).filter(lang => criteria.languages[lang]);
  if (selectedLanguages.length > 0) {
    filters.push(where('language', 'in', selectedLanguages));
  }

  // Crear la consulta con los filtros aplicados
  const q = query(usersRef, ...filters);
  const querySnapshot = await getDocs(q);

  const users = [];
  const numericIdSet = new Set(); // Para asegurar la unicidad de los IDs numéricos

  querySnapshot.forEach((doc) => {
    const user = doc.data();
    const numericUserIdStr = extractNumbers(doc.id); // Extraer solo números del doc.id
    const numericUserId = parseInt(numericUserIdStr, 10); // Convertir la cadena a número

    // Validar que haya números y que el ID sea único
    if (!numericUserIdStr) {
      console.warn(`El doc.id: ${doc.id} no contiene números. Usuario omitido.`);
      return; // Omitir este usuario
    }

    if (numericIdSet.has(numericUserId)) {
      console.warn(`ID numérico duplicado: ${numericUserId}. Usuario omitido.`);
      return; // Omitir este usuario para evitar duplicados
    }

    numericIdSet.add(numericUserId); // Agregar el ID al conjunto para seguimiento

    users.push({
      userId: numericUserId,
      language: user.language,
      email: user.email,
      firstName: user.name || '',
      purchaser: user.shop.totalRealitiesAcquired > 0,
      realityCreated: user.shop.totalRealitiesCreated > 0,
    });
  });

  return users;
};

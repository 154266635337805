import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { formatDate } from '../../utils/dates';
import { langFlags } from '../../common/langFlags';
import { timeAgo } from '../../utils/dates';

// Bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root'); // Adjust if your root element has a different ID

function capitalize(text) {
  if (!text) return '';
  return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
}

const Realities = ({ user }) => {
  const [openReality, setOpenReality] = useState(user.realities.length === 1 ? 0 : null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState({
    realityIndex: null,
    imageIndex: null,
  });

  // Toggle the visibility of a reality's details
  const toggleReality = (index) => {
    setOpenReality(openReality === index ? null : index);
  };

  // Render icons based on the creator type
  const renderIconForCreatedBy = (type) => {
    if (type?.toLowerCase() === 'speaking') {
      return <i className="fas fa-microphone" style={{ color: '#007bff', marginLeft: '0.5rem' }} title="Speaking"></i>;
    }
    if (type?.toLowerCase() === 'writing') {
      return <i className="fas fa-pencil-alt" style={{ color: '#007bff', marginLeft: '0.5rem' }} title="Writing"></i>;
    }
    return null;
  };

  // Open the modal with the selected image
  const openImageModal = (realityIndex, imageIndex) => {
    setSelectedImage({ realityIndex, imageIndex });
    setIsModalOpen(true);
    document.body.style.overflow = 'hidden'; // Prevent background scrolling
  };

  // Close the modal
  const closeImageModal = () => {
    setSelectedImage({ realityIndex: null, imageIndex: null });
    setIsModalOpen(false);
    document.body.style.overflow = 'auto'; // Restore background scrolling
  };

  // Handle keyboard events for accessibility
  const handleKeyDown = (e) => {
    if (e.key === 'Escape' && isModalOpen) {
      closeImageModal();
    }
    if (e.key === 'ArrowLeft' && isModalOpen) {
      navigatePrevImage();
    }
    if (e.key === 'ArrowRight' && isModalOpen) {
      navigateNextImage();
    }
  };

  useEffect(() => {
    if (isModalOpen) {
      window.addEventListener('keydown', handleKeyDown);
    } else {
      window.removeEventListener('keydown', handleKeyDown);
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isModalOpen, selectedImage]);

  // Navigate to the previous image within the same reality
  const navigatePrevImage = () => {
    const { realityIndex, imageIndex } = selectedImage;
    if (realityIndex === null || imageIndex === null) return;

    const reality = user.realities[realityIndex];
    if (!reality || !reality.images) return;

    if (imageIndex > 0) {
      setSelectedImage({ realityIndex, imageIndex: imageIndex - 1 });
    }
  };

  // Navigate to the next image within the same reality
  const navigateNextImage = () => {
    const { realityIndex, imageIndex } = selectedImage;
    if (realityIndex === null || imageIndex === null) return;

    const reality = user.realities[realityIndex];
    if (!reality || !reality.images) return;

    if (imageIndex < reality.images.length - 1) {
      setSelectedImage({ realityIndex, imageIndex: imageIndex + 1 });
    }
  };

  return (
    <div className="third-section">
      <h2>Realities</h2>
      {user.realities.map((reality, index) => {
        const isOpen = openReality === index;
        const isCurrentReality = reality.id === user.currentReality;

        return (
          <div key={reality.id} className={`reality-item ${isCurrentReality ? 'current-reality' : ''}`}>
            <div className="reality-header" onClick={() => toggleReality(index)}>
              <strong>{reality.title}</strong>
              <div className="reality-meta">
                <span>{reality.currentDay}</span>
                {reality.createdBy && (
                  <p className="reality-createdBy">{renderIconForCreatedBy(reality.createdBy)}</p>
                )}
                <span className={`bold ${reality.isPaid ? 'purple-badge' : 'green-badge'}`}>
                  {reality.isPaid ? 'PRO' : 'FREE'}
                </span>
                <span className={isCurrentReality ? 'green-badge' : 'red-badge'}>
                  {isCurrentReality ? 'Current' : 'Inactive'}
                </span>
              </div>
              <span className={`toggle-icon ${isOpen ? 'open' : ''}`}>
                <i className={`bx ${isOpen ? 'bx-chevron-down' : 'bx-chevron-right'}`}></i>
              </span>
            </div>
            {isOpen && (
              <div className="reality-details">
                <div className="data-row">
                  <strong className="data-key">ID:</strong>
                  <p>{reality.id}</p>
                </div>
                <div className="data-row">
                  <strong className="data-key">Created by:</strong>
                  <p>
                    {capitalize(reality.createdBy)} {renderIconForCreatedBy(reality.createdBy)}
                  </p>
                </div>
                <div className="data-row">
                  <strong className="data-key" style={{ fontWeight: 'bold' }}>Current Day:</strong>
                  <p>{reality.currentDay}</p>
                </div>
                <div className="data-row">
                  <strong className="data-key">Created At:</strong>
                  <p>
                    {formatDate(reality.createdAt, true)} - <span className="green-badge">{timeAgo(reality.createdAt)}</span>
                  </p>
                </div>
                <div className="data-row">
                  <strong className="data-key">Last Updated:</strong>
                  <p>
                    {formatDate(reality.updatedAt, true)} - <span className="green-badge">{timeAgo(reality.updatedAt)}</span>
                  </p>
                </div>
                <div>
                  <strong className="data-key">Prompt 1:</strong>
                  <p>{reality.prompt1}</p>
                </div>
                <div>
                  <strong className="data-key">Prompt 2:</strong>
                  <p>{reality.prompt2}</p>
                </div>
                <div className="data-row">
                  <strong className="data-key">Voice Display Name:</strong>
                  <p>{reality.voiceDisplayName}</p>
                </div>
                <div className="data-row">
                  <strong className="data-key">Language:</strong>
                  <img
                    src={langFlags[reality.lang]}
                    alt={reality.lang}
                    title={reality.lang}
                    className="flag"
                  />
                </div>
                <div className="data-row">
                  <strong className="data-key">Is Paid:</strong>
                  <p className={reality.isPaid ? 'green-badge' : 'red-badge'}>
                    {reality.isPaid ? 'Yes' : 'No'}
                  </p>
                </div>
                <div className="data-row">
                  <strong className="data-key">Reality Number:</strong>
                  <p>{reality.realityNumber}</p>
                </div>
                <div className="data-row">
                  <strong className="data-key">Start Day:</strong>
                  <p>{reality.startDay}</p>
                </div>

                {/* Reality Images Section */}
                {reality.images && reality.images.length > 0 && (
                  <div className="reality-images-section">
                    <h3>Reality Images</h3>
                    <div className="images-gallery">
                      {reality.images.map((imageUrl, idx) => (
                        <div
                          key={idx}
                          className="image-thumbnail"
                          onClick={() => openImageModal(index, idx)}
                        >
                          <img src={imageUrl} alt={`Reality Image ${idx + 1}`} />
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        );
      })}

      {/* Full-Screen Image Modal */}
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeImageModal}
        contentLabel="Reality Image"
        className="modal-content"
        overlayClassName="modal-overlay"
      >
        <button className="close-button" onClick={closeImageModal} aria-label="Close">&times;</button>
        {selectedImage.realityIndex !== null && selectedImage.imageIndex !== null && (
          <img
            src={user.realities[selectedImage.realityIndex].images[selectedImage.imageIndex]}
            alt={`Full Screen Reality Image ${selectedImage.imageIndex + 1}`}
            className="full-image"
          />
        )}
        {/* Navigation Buttons */}
        {selectedImage.realityIndex !== null && selectedImage.imageIndex > 0 && (
          <button
            className="nav-button left"
            onClick={() =>
              setSelectedImage((prev) => ({
                ...prev,
                imageIndex: prev.imageIndex - 1,
              }))
            }
            aria-label="Previous Image"
          >
            &#10094;
          </button>
        )}
        {selectedImage.realityIndex !== null &&
          selectedImage.imageIndex < user.realities[selectedImage.realityIndex].images.length - 1 && (
            <button
              className="nav-button right"
              onClick={() =>
                setSelectedImage((prev) => ({
                  ...prev,
                  imageIndex: prev.imageIndex + 1,
                }))
              }
              aria-label="Next Image"
            >
              &#10095;
            </button>
          )}
      </Modal>
    </div>
  );
};

export default Realities;

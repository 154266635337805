import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchEmailsRequest } from '../../store/emails/actions';
import { appLanguages } from '../../common/languages';
import { langFlags } from '../../common/langFlags';

const initialCriteria = {
  allEmails: false,
  marketingAccepted: false,
  purchasers: false,
  nonPurchasers: false,
  realityCreated: false,
  nonRealityCreated: false,
  languages: appLanguages.reduce((acc, lang) => {
    acc[lang] = false;
    return acc;
  }, {}),
};

const checkboxLabels = {
  allEmails: 'All Emails',
  marketingAccepted: 'Marketing Accepted',
  purchasers: 'Purchasers',
  nonPurchasers: 'Non Purchasers',
  realityCreated: 'Reality Created',
  nonRealityCreated: 'Non Reality Created',
};

const Emails = () => {
  const dispatch = useDispatch();
  const users = useSelector(state => state.emails.emails);
  const [criteria, setCriteria] = useState(initialCriteria);
  const [emailList, setEmailList] = useState('');
  const [copied, setCopied] = useState(false);
  const [loading, setLoading] = useState(false);
  const [noItemsFound, setNoItemsFound] = useState(false);

  useEffect(() => {
    const formattedUsers = users
      .filter(user => user.email && !user.email.includes('privaterelay.appleid.com'))
      .map(user => {
        const { userId, language, email, firstName, purchaser, realityCreated } = user;
        return `${userId},${language},${email},${firstName},${purchaser},${realityCreated}`;
      });

    const emailListContent = `CONTACT ID,LANGUAGE,EMAIL,FIRSTNAME,LASTNAME,PURCHASER,REALITY_CREATED\n${formattedUsers.join('\n')}`;

    setEmailList(emailListContent);
    setLoading(false);
    setNoItemsFound(formattedUsers.length === 0);
  }, [users]);

  useEffect(() => {
    if (noItemsFound) {
      setTimeout(() => setNoItemsFound(false), 5000);
    }
  }, [noItemsFound]);

  const handleCheckboxChange = (category, value) => {
    if (category === 'allEmails') {
      setCriteria({
        ...initialCriteria,
        allEmails: value,
      });
    } else if (category in criteria.languages) {
      setCriteria({
        ...criteria,
        languages: {
          ...criteria.languages,
          [category]: value,
        },
      });
    } else {
      setCriteria({
        ...criteria,
        [category]: value,
      });
    }
  };

  const handleSubmit = () => {
    setLoading(true);
    dispatch(fetchEmailsRequest(criteria));
  };

  const handleClear = () => {
    setCriteria(initialCriteria);
    setEmailList('');
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(emailList).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    });
  };

  const isClearDisabled = !emailList;
  const isSubmitDisabled = !Object.values(criteria).some(val => val === true || Object.values(val).some(innerVal => innerVal === true));
  const emailCount = emailList.split('\n').filter(line => line && !line.startsWith('CONTACT ID')).length;

  return (
    <div id="emails-page-content" className="page-content">
      <div className="emails-content-row">
        <div className="emails-current-sentences white-block">
          <div className="emails-button-row">
            <div className="form-check-wrapper">
              {Object.keys(checkboxLabels).map(key => (
                <div key={key} className={`form-check ${criteria[key] ? 'checked' : ''}`}>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id={key}
                    checked={criteria[key]}
                    onChange={(e) => handleCheckboxChange(key, e.target.checked)}
                    disabled={criteria.allEmails && key !== 'allEmails'}
                  />
                  <label className="form-check-label" htmlFor={key}>
                    {checkboxLabels[key]}
                  </label>
                </div>
              ))}
            </div>
            <div className="form-check-wrapper">
              {appLanguages.map((lang) => (
                <div key={lang} className={`form-check ${criteria.languages[lang] ? 'checked' : ''}`}>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id={`lang-${lang}`}
                    checked={criteria.languages[lang]}
                    onChange={(e) => handleCheckboxChange(lang, e.target.checked)}
                    disabled={criteria.allEmails}
                  />
                  <label className="form-check-label" htmlFor={`lang-${lang}`}>
                    <img
                      src={langFlags[lang]}
                      alt={`${lang} flag`}
                      style={{ width: '20px', height: '20px', marginRight: '5px' }}
                    />
                    {lang}
                  </label>
                </div>
              ))}
            </div>
            <div className="emails-button-group">
              <button className="white-button" onClick={handleClear} disabled={isClearDisabled}>Clear</button>
              <button className="button" onClick={handleSubmit} disabled={isSubmitDisabled}>
                {loading ? 'Loading...' : 'Submit'}
              </button>
            </div>
            {noItemsFound && <div className="no-items-message">No items found</div>}
          </div>
        </div>
        <div className="emails-current-sentences white-block">
          <textarea className="email-textarea" value={emailList} onChange={(e) => setEmailList(e.target.value)} rows="20" cols="50" />
          <div className="emails-button-group">
            <button className="white-button" onClick={() => setEmailList('')} disabled={isClearDisabled}>Clear</button>
            {emailCount > 0 && <div className="email-count">{emailCount} emails</div>}
            <button className="button" onClick={handleCopy}>Copy</button>
          </div>
          {copied && <div className="copied-message">Copied!</div>}
        </div>
      </div>
    </div>
  );
};

export default Emails;

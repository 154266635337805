import React, { useEffect, useState } from "react";
import { getStats } from "../../helpers/API/stats";
import { langFlags } from "../../common/langFlags";
import RefreshButton from "../../components/Common/RefreshButton";
import { formatNumber } from "../../utils/numbers";

const Stats = () => {
  const [stats, setStats] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = async () => {
    setIsLoading(true);
    const data = await getStats();
    setStats(data);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (!stats) {
    return (
      <div className="page-content stats">
        <div className="spinner-border text-primary"></div>
      </div>
    );
  }

  const statsGroups = {
    group1: [
      {
        label: "Users",
        value: formatNumber(stats.totalUsers),
        icon: "bx bx-user",
        color: "green",
      },
      {
        label: "Male users",
        value: formatNumber(stats.maleUsers.length),
        ratio: stats.maleRatio,
        icon: "bx bx-male",
        extraClass: "highlighted blue",
      },
      {
        label: "Female users",
        value: formatNumber(stats.femaleUsers.length),
        ratio: stats.femaleRatio,
        icon: "bx bx-male",
        extraClass: "highlighted pink",
      },
      {
        label: "New Users Last 24 Hours",
        value: formatNumber(stats.newUsersLast24Hours),
        ratio: stats.ratioNewUsersLast24Hours,
        icon: "bx bx-stats",
        extraClass: "highlighted green",
      },
      {
        label: "New Users Last 10 Days",
        value: formatNumber(stats.newUsersLast10Days),
        ratio: stats.ratioNewUsersLast10Days,
        icon: "bx bx-stats",
      },
      {
        label: "New Users Last 30 Days",
        value: formatNumber(stats.newUsersLast30Days),
        ratio: stats.ratioNewUsersLast30Days,
        icon: "bx bx-stats",
      },
      {
        label: "Current Reality",
        value: formatNumber(stats.totalUsersWithCurrentReality),
        ratio: stats.ratioUsersWithRealityCreated,
        icon: "bx bx-user-circle",
        extraClass: "highlighted orange",
      },
      {
        label: "Current reality 30 D",
        value: formatNumber(stats.totlalUsersWithRealityLast30Days),
        ratio: stats.ratioTotalUsersWithRealityLast30Days,
        icon: "bx bx-stats",
      },
      {
        label: "Free Demo Completed",
        value: formatNumber(stats.totalUsersWithFreeDemoCompleted),
        ratio: stats.ratioUsersWithFreeDemoCompleted,
        icon: "bx bx-stats",
        extraClass: "highlighted orange",
      },
      {
        label: "Free Demo Completed Last 10 Days",
        value: formatNumber(stats.totalUsersWithFreeDemoCompletedLast10Days),
        ratio: stats.ratioUsersWithFreeDemoCompletedLast10Days,
        icon: "bx bx-stats",
        color: "green",
        extraClass: "highlighted green",
      },
      {
        label: "Free demo completed 30 D",
        value: formatNumber(stats.totalUsersWithFreeDemoCompletedLast30Days),
        ratio: stats.ratioTotalUsersWithFreeDemoCompletedLast30Days,
        icon: "bx bx-stats",
      },
      {
        label: "Ratio of demo completed purchasers",
        value: formatNumber(stats.purchasersWithFreeDemoCompleted),
        ratio: stats.ratioFreeDemoCompletedPurchasers,
        icon: "bx bx-globe",
      },
      {
        label: "Total users withs Ads Consented",
        value: formatNumber(stats.totalUsersWithAds),
        ratio: stats.ratioTotalUsersWithAds,
        icon: "bx bx-user-check",
        extraClass: "highlighted orange",
      },
      {
        label: "Total Ads Seen",
        value: `${formatNumber(stats.totalAdsSeen)} - $${(stats.totalAdsSeen*0.011).toFixed(2)}`,
        icon: "bx bx-ad",
        extraClass: "highlighted green",
      },
      {
        label: "Ads per user",
        value: `${formatNumber(stats.avgAdsSeenPerUser)} - $${(stats.avgAdsSeenPerUser*0.011).toFixed(2)}`,
        // ratio: stats.avgProfitPerUser,
        icon: "bx bx-ad",
      },
      {
        label: "Marketing consented",
        value: formatNumber(stats.totalUsersWithMarketingConsented),
        ratio: stats.ratioTotalUsersWithMarketingConsented,
        icon: "bx bx-user-check",
        extraClass: "highlighted pink",
      },
      {
        label: "Total referred users",
        value: formatNumber(stats.totalReferrals),
        // ratio: stats.totalReferrals,
        icon: "bx bx-user-plus",
      }
    ],
    group2: [
      {
        label: "Purchasers",
        value: formatNumber(stats.totalPurchasers),
        ratio: stats.ratioPurchasers,
        icon: "bx bx-stats",
        color: "green",
      },
      {
        label: "Total Purchases",
        value: formatNumber(stats.totalPurchases),
        ratio: stats.ratioPurchasesByPurchasers,
        icon: "bx bx-cart",
      },
      {
        label: "Female purchasers",
        value: formatNumber(stats.femalePurchasers),
        ratio: stats.ratioFemalePurchase,
        icon: "bx bx-cart",
        extraClass: "highlighted pink",
      },
      {
        label: "Male purchasers",
        value: formatNumber(stats.malePurchasers),
        ratio: stats.ratioMalePurchase,
        icon: "bx bx-cart",
        extraClass: "highlighted blue",
      },
      {
        label: "Non-Purchasers",
        value: formatNumber(stats.totalNonPurchasers),
        ratio: stats.ratioNonPurchasers,
        icon: "bx bx-user-x",
      },
      {
        label: "Purchasers Of 3 Realities",
        value: stats.totalPurchasersOf3Realities,
        ratio: stats.ratioPurchaseOf3Realities,
        icon: "bx bx-cart-alt",
      },
      {
        label: "Realities Acquired",
        value: formatNumber(stats.totalRealitiesAcquired),
        ratio: stats.ratioOfRealitiesAcquired,
        icon: "bx bx-globe",
      },
      {
        label: "Purchases last 24 H.",
        value: stats.purchasesLast24Hours,
        ratio: `$${stats.amountPurchasesLast24Hours}`,
        icon: "bx bx-stats",
        extraClass: "highlighted green",
      },
      {
        label: "Purchases last 10 D.",
        value: stats.purchasesLast10Days,
        ratio: `$${stats.amountPurchasesLast10Days}`,
        icon: "bx bx-stats",
        color: "green",
      },
      {
        label: "Purchases last 30 D.",
        value: stats.purchasesLast30Days,
        ratio: `$${formatNumber(stats.amountPurchasesLast30Days)}`,
        icon: "bx bx-stats",
      },
      {
        label: "Average Price",
        value: stats.averagePrice,
        currency: "$",
        icon: "bx bx-dollar-circle",
        color: "green",
      },
      {
        label: "Total Income",
        value: formatNumber(stats.totalCost),
        currency: "$",
        icon: "bx bx-money",
        color: "green",
        extraClass: "highlighted orange",
      },
      {
        label: "Expected purchases next 10 days",
        value: stats.expectedPurchasesNext10Days,
        ratio: `$${formatNumber(stats.expectedIncomeNext10Days)}`,
        icon: "bx bx-stats",
        color: "green",
      },
      {
        label: "Expected purchases monthly",
        value: stats.expectedPurchasesMonthly,
        ratio: `$${formatNumber(stats.expectedIncomeMonthly)}`,
        icon: "bx bx-stats",
        color: "green",
        extraClass: "highlighted green",
      },
      {
        label: "Avg. time sign up > purchase",
        value: `${stats.avgTimeFromSignupToPurchase.toFixed(2)} h. - ${(stats.avgTimeFromSignupToPurchase / 24).toFixed(2)} days`,
        icon: "bx bx-time",
      },
    ],
    group3: stats.ratioPerLanguage.map(item => ({
      label: `<img src="/path/to/flags/${item.language}.png" alt="${item.language} flag"> ${item.language}`,
      value: formatNumber(item.users),
      ratio: item.ratio,
      language: item.language
    })),
  };


  return (
    <div className="page-content stats">
      <div className="stats-container">
        <RefreshButton onClick={fetchData} extraClass="refresh" isLoading={isLoading} />
        {Object.entries(statsGroups).map(([groupName, group], index) => (
          <div key={index} className="stats-block">
            {isLoading && (
              <div className="loading">
                <div className="spinner"></div>
              </div>
            )}
            {groupName === "group3" && (
              <div className="stats-item header">
                <label className="stats-label bold">Lang</label>
                <p className="stats-value bold">Users</p>
                <p className="stats-value bold">Purchases</p>
              </div>
            )}
            {group.map((item, index) => (
              <div
                key={index}
                className={`
                  stats-item ${item.extraClass || ""} ${index === 0 ? "highlighted green" : ""}
                `}>
                <label className="stats-label">
                  {groupName === "group3" ? (
                    <>
                      <img
                        src={langFlags[item.language]}
                        alt={`${item.language} flag`}
                        style={{
                          width: "20px",
                          height: "20px",
                          marginRight: "5px"
                        }} />
                      <span>{item.language}</span>
                    </>
                  ) : (
                    item.label
                  )}
                  {item.icon && <i className={item.icon} style={{ marginLeft: "5px" }}></i>}
                </label>
                <p className={`stats-value stats-value-${item.color}`}>
                  {item.currency && <span className="stats-currency">{item.currency}</span>}
                  {item.value}
                  {item.ratio && <span className="stats-ratio">({item.ratio})</span>}
                </p>
                {groupName === "group3" && (
                  <p className={`stats-value stats-value-${item.color}`}>
                    {stats.purchasesPerLanguage[item.language] > 0 ? (
                      <>
                        {stats.purchasesPerLanguage[item.language]}
                        <span className="stats-ratio"> ({stats.ratioPurchasesPerLanguage[item.language]})</span>
                      </>
                    ) : "-"}
                  </p>
                )}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}

export default Stats;
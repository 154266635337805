import { initializeApp, getApps, getApp, FirebaseApp } from 'firebase/app';
import {
  initializeFirestore,
  persistentLocalCache,
  persistentMultipleTabManager,
  Firestore,
} from 'firebase/firestore';
import { getStorage, FirebaseStorage } from 'firebase/storage';
import { getAuth, Auth } from 'firebase/auth';
import { getFunctions, Functions } from 'firebase/functions';
import Config from '../config';

// const firebaseConfig = Config.firebaseConfig_DEV; // DEV
const firebaseConfig = Config.firebaseConfig_PROD; // PROD

interface FirebaseServices {
  app: FirebaseApp;
  auth: Auth;
  db: Firestore;
  storage: FirebaseStorage;
  functions: Functions;
}

// Initialize Firebase services singleton to ensure they are set up only once
const initializeFirebaseServices = (): FirebaseServices => {
  let app: FirebaseApp;
  let auth: Auth;
  let db: Firestore;
  let storage: FirebaseStorage;
  let functions: Functions;

  if (!getApps().length) {
    try {
      app = initializeApp(firebaseConfig);
      auth = getAuth(app);

      // **Initialize Firestore with persistence settings**
      db = initializeFirestore(app, {
        localCache: persistentLocalCache({
          tabManager: persistentMultipleTabManager(),
        }),
      });

      storage = getStorage(app);
      functions = getFunctions(app, Config.firebaseRegion);
    } catch (error: any) {
      console.error('Error initializing Firebase services: ', error.message);
      throw new Error('Failed to initialize Firebase services');
    }
  } else {
    app = getApp();
    auth = getAuth(app);

    // **If Firestore has already been initialized, get the instance**
    db = initializeFirestore(app, {
      localCache: persistentLocalCache({
        tabManager: persistentMultipleTabManager(),
      }),
    });

    storage = getStorage(app);
    functions = getFunctions(app, Config.firebaseRegion);
  }

  return { app, auth, db, storage, functions };
};

const { app, auth, db, storage, functions } = initializeFirebaseServices();

export { app, auth, db, storage, functions, firebaseConfig };
